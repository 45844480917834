import "./style.scss";
import Glide from "@glidejs/glide";

import "@glidejs/glide/dist/css/glide.core.css";

import domReady from "@wordpress/dom-ready";

function Slider() {
  const qoutes = document.querySelectorAll(".qoutes-slider")[0];

  var glide = new Glide(".qoutes-slider", {
    type: "carousel",
    perView: 3,
    focusAt: "center",
    breakpoints: {
      800: {
        perView: 2,
      },
      480: {
        perView: 1,
      },
    },
  });

  glide.mount();
}

domReady(Slider);
